<template>
  <HeaderPanel />
  <MainPanel />
  <FooterPanel />
</template>

<script>
import HeaderPanel from './components/HeaderPanel.vue'
import MainPanel from './components/MainPanel.vue'
import FooterPanel from './components/FooterPanel.vue'

export default {
  name: 'App',
  components: {
    HeaderPanel, MainPanel, FooterPanel
  },
  mounted: function () {
    fetch('https://bing.biturl.top/?resolution=1920&format=json&index=0&mkt=zh-CN')
      .then(response => response.json())
      .then(data => {
        const imageUrl = data.url;
        document.body.style.backgroundImage = `url('${imageUrl}')`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
      })
      .catch(error => console.error('Error fetching Bing image:', error));
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  /* 背景图垂直、水平均居中 */
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  background-attachment: fixed;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;
  /* 设置背景颜色，背景图加载过程中会显示背景色 */
  background-color: #464646;
  /* 背景模糊 */
  backdrop-filter: blur(4px);
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <footer></footer>
</template>
<script>
export default {
  name: 'FooterPanel',
  props: {}
}
</script>
<style scoped>
header{
  height: 3rem;
  background-color: #ffffff22;
}
</style>
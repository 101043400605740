<template>
    <main>
      <SearchPanel></SearchPanel>
    </main>
</template>
<script>
import SearchPanel from './SearchPanel.vue';

export default {
    name: 'MainPanel',
    props: {},
    components: { SearchPanel }
}
</script>
<style scoped>
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>
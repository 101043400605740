<template>
  <header></header>
</template>
<script>
export default {
  name: 'HeaderPanel',
  props: {}
}
</script>
<style scoped>
header{
  height: 3rem;
}
</style>
<template>
  <div class="search">
    <input type="search" placeholder="Search" v-model="searchText">
    <button type="button" @click="Search(searchText)">Search</button>
  </div>
</template>
  
<script>

import { ref } from 'vue';

export default {
  setup() {
    let searchText = ref('')
    return { searchText }
  },
  name: 'SearchPanel',
  props: {  },
  methods: {
    Search(text) {
      console.log(text);
      document.location.href = 'https://cn.bing.com/search?q='+text
    }
  }
}
</script>
  
<style scoped>
.search {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search>input {
  width: 30rem;
  height: 3rem;
  border-radius: 30px 0px 0px 30px;
  padding-left: 20px;
  border: none;
  font: 1.5rem sans-serif;
}

.search>input:focus {
  outline: coral solid 1px;
}

.search>button {
  width: 6rem;
  height: 3rem;
  border: none;
  background-color: rgb(40, 223, 223);
  color: #fff;
  border-radius: 0px 20px 20px 0px;
  font-size: 20px;
}

.search>button:hover {
  background-color: rgb(26, 158, 158);
}
</style>
  